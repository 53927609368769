<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <EirReport
        :FormatedDate="pageInfo.FormatedDate"
        :Devolution="pageInfo.Devolution"
        :VehicleName="pageInfo.VehicleName"
        :OperatorName="pageInfo.OperatorName"
        :ClientName="pageInfo.ClientName"
        :TransportName="pageInfo.TransportName"
        :DriverName="pageInfo.DriverName"
        :ObservationUser="pageInfo.ObservationUser"
        :FgEirContainerCondiction="pageInfo.FgEirContainerCondiction"
        :arrIdsDamage="DamageList"
        :arrIdsAccessories="AccessoryList"
        :infoContainer="pageInfo.infoContainer"
        :Pages="Pages"
        :Preview="pageInfo.Preview"
      />
    </div>
  </transition>
</template>

<script>
import EirReport from "./eir-report";

//Data
function data() {
  return {
    pageInfo: {
      FormatedDate: 'DD/MM/YYYY',
      Devolution: '',
      VehicleName: '',
      OperatorName: '',
      ClientName: '',
      TransportName: '',
      DriverName: '',
      FgEirContainerCondiction: false,
      arrIdsDamage: [],
      arrIdsAccessories: [],
      infoContainer: {},
      ObservationUser: '',
      Visit: '',
      Date: '',
      CraneName: '',
      Report: 1,
      Preview: false,
    },
    AccessoryList: [],
    DamageList: [],
    Pages: 1,
  };
}

//Created
async function mounted() {
  let info = JSON.parse(localStorage.getItem("pageInfo"));

  if (!info) {
    window.close();
    return;
  }

  this.pageInfo = info;

  await this.TotalPages(this.pageInfo.arrIdsDamage, this.pageInfo.arrIdsAccessories);
  setTimeout(() => {
    window.print();
  }, 250),
 
  localStorage.removeItem("pageInfo");
  
  window.addEventListener("afterprint", function(event) {
    close();
  });
}



//Methods:
function TotalPages(arrIdsDamage, arrIdsAccessories) {
  let AccessoryList = [];
  let AccessorySubList = [];
  let DamageList = [];
  let DamageSubList = [];
  let Cont = 0;
  let Aux = 12; //cantidad de linea por hoja
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let length = arrIdsAccessories.length > arrIdsDamage.length ? arrIdsAccessories.length : arrIdsDamage.length;
  for (let index = 0; index < length; index++) {
    if (arrIdsDamage[index]&&Cont<Aux) {
      DamageSubList.push(arrIdsDamage[index]);
      if (arrIdsDamage[index]?.Observation?.length > 133 ||
          arrIdsDamage[index][`TpDamageName${_lang}`]?.length > 12 ||
          arrIdsDamage[index][`TpCargoViewName${_lang}`]?.length > 9 
        ) 
      {
        Cont++;
      }
      Cont++;
    }else if(arrIdsDamage[index]){
      DamageList.push(DamageSubList);
      DamageSubList = [];
      DamageSubList.push(arrIdsDamage[index]);
      if (AccessorySubList.length!=0) {
        AccessoryList.push(AccessorySubList);
        AccessorySubList = [];
      }
      Cont = 0;
    }
    if (arrIdsAccessories[index]&&Cont<Aux) {
      AccessorySubList.push(arrIdsAccessories[index]);
      if (arrIdsAccessories[index]?.Observation?.length > 130) {
        Cont++;
      }
      Cont++;
    }else if(arrIdsAccessories[index]){
      AccessoryList.push(AccessorySubList);
      AccessorySubList = [];
      AccessorySubList.push(arrIdsAccessories[index]);
      if (DamageSubList.length!=0) {
        DamageList.push(DamageSubList);
        DamageSubList = [];
      }
      Cont = 0;
    }
  }
  AccessoryList.push(AccessorySubList);
  DamageList.push(DamageSubList);
  this.AccessoryList = AccessoryList;
  this.DamageList = DamageList;
  this.Pages = this.AccessoryList.length > this.DamageList.length ? this.AccessoryList.length : this.DamageList.length;
}

export default {
  name: "sumary-report-PDF",
  components: {
    EirReport,
  },
  data,
  mounted,
  methods: {
    TotalPages,
  },
  computed: {},
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}
</style>